import React from 'react'
import { Route, Routes } from 'react-router-dom';
import ReportSummary from '../../pages/chart/home';
import SituationAssessmentIndex from '../../pages/situationAssessment';

const AccessRoute = () => {
  return ( 
    <>
    <Routes>
     <Route path='/' element={<SituationAssessmentIndex />} />
    <Route path='/situation' element={<SituationAssessmentIndex />} />
    <Route path='/completed' element={<ReportSummary />} />
    </Routes>
    </>
  )
}

export default AccessRoute;