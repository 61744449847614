import axios from "axios";



export const getStituation = async(id) => {
    const query = `?situation_id=${id}`;
    const header = {
        // Authorization : `Bearer ${token}`,
        // 'Access-Control-Allow-Origin':true,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS'
    }
    try {
        const data = await axios.get(window.GET_SITUATION+query,{headers:header})
        return data;
    } catch (error) {
        
    }
}