import React, { useEffect, useRef, useState } from "react";
import { Container, Card, Button } from "react-bootstrap";
import { Radar } from "react-chartjs-2";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import img2 from "../../assets/images/new2.jpg";
import { useDispatch, useSelector } from "react-redux";
import { setResetData } from "../../feature/slice/tabSlice";
import { setResetUser } from "../../feature/slice/selfSlice";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import axios from "axios";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
// import existing from "../../assets/pdf"
import { PDFDocument } from "pdf-lib";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const btnStyle = {
  botton: "10px",
  border: "none",
  padding: "10px",
  marginTop: "10px",
};
let data = {
  labels: [
    "Assertiveness",
    "Decisiveness",
    "Independence",
    "Goal Orientation",
    "Balanced",
    "Option",
    "djcj",
  ],
  datasets: [
    {
      label: "Your Score dsjnvf",
      data: [3.1, 4.7, 2, 5, 3, 4],
      backgroundColor: "rgba(255, 99, 132, 0.2)",
      borderColor: "rgba(255, 99, 132, 1)",
      pointBackgroundColor: "rgba(255, 99, 132, 1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(255, 99, 132, 1)",
    },
  ],
};

const options = {
  scales: {
    r: {
      ticks: { beginAtZero: true, min: 0, max: 9, stepSize: 2 },
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display:false,
      labels: {
        boxWidth: 10,
        boxHeight: 10,
        padding: 10,
        color: "rgba(255, 99, 132, 1)",
        font: {
          size: 14,
        },
        generateLabels: (chart) => {
          const datasets = chart.data.datasets;
          return datasets.map((dataset, i) => ({
            text: dataset.label,
            fillStyle: dataset.borderColor,
            strokeStyle: dataset.borderColor,
            hidden: !chart.isDatasetVisible(i),
            lineCap: dataset.borderCapStyle,
            lineDash: dataset.borderDash,
            lineDashOffset: dataset.borderDashOffset,
            lineJoin: dataset.borderJoinStyle,
            lineWidth: dataset.borderWidth,
            pointStyle: "rectRounded",
            rotation: 0,
          }));
        },
      },
    },
  },
};

function ReportSummary() {
  const [state, setState] = useState({
    mainData: {},
  });
  const { mainData } = state;
  const { steps } = useSelector((data) => data.TabSlice);
  const { userId } = useSelector((data) => data.SelfSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (steps !== 20) {
      navigate("/");
    }

    getIntial();
  }, []);

  const getIntial = async () => {
    const query = `?user_id=${userId}`;
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      const result = await axios.get(window.GET_SPIDER_DATA + query, {
        headers: headers,
      });
      const innerData = {
        labels: Object.keys(result.data),
        datasets: [
          {
            label: "Your Score",
            data: Object.values(result.data),
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            borderColor: "rgba(255, 99, 132, 1)",
            pointBackgroundColor: "rgba(255, 99, 132, 1)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgba(255, 99, 132, 1)",
          },
        ],
      };
      setState((prev) => ({ ...prev, mainData: innerData }));
    } catch (error) {}
  };

  const handleSubmit = () => {
    dispatch(setResetData());
    dispatch(setResetUser());
    navigate("/");
  };

  const buttonRef = useRef(null);
  const photoRef = useRef(null);
  const backRef = useRef(null);
  
  // const takeScreenshotAndSavePDF = () => {
  //   buttonRef.current.style.display = 'none';
  //   backRef.current.style.display = 'none';
  //   if (photoRef.current) {
  //     html2canvas(photoRef.current, { scale: 2 }).then((canvas) => {
  //       buttonRef.current.style.display = 'block';
  //       backRef.current.style.display = 'block';
  //       const imgData = canvas.toDataURL('image/png');
  //       const pdf = new jsPDF('p', 'mm', 'a4');
  //       const pdfWidth = pdf.internal.pageSize.getWidth();
  //       const pdfHeight = pdf.internal.pageSize.getHeight();
  //       const imgWidth = pdfWidth;
  //       const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //       if (imgHeight <= pdfHeight) {
  //         pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
  //       } else {
  //         let position = 0;
  //         while (position < imgHeight) {
  //           pdf.addImage(imgData, 'PNG', 0, position, imgWidth, pdfHeight);
  //           position += pdfHeight;
  //           if (position < imgHeight) pdf.addPage();
  //         }
  //       }
  //       pdf.save('AssessmentRecord.pdf');
  //     });
  //   }
  // }

  const takeScreenshotAndSavePDF = async () => {
    // buttonRef.current.style.display = 'none';
    // backRef.current.style.display = 'none';
  
    try {
      if (photoRef.current) {
        // Take a screenshot using html2canvas
        const canvas = await html2canvas(photoRef.current, { scale: 2 });
        // buttonRef.current.style.display = 'block';
        // backRef.current.style.display = 'block';
  
        const imgData = canvas.toDataURL('image/png');
  
        // Load the existing PDF file
        const existingPdfBytes = await fetch('/gc.pdf').then((res) => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(existingPdfBytes);
  
        // Get the second page of the PDF
        const pages = pdfDoc.getPages();
        console.log(pages);
        
        const secondPage = pages[1]; // Index 1 is the second page
        const pageHeight = secondPage.getHeight();
        const pageWidth = secondPage.getWidth();
  
        // Get the dimensions of the second page
        const { width, height } = secondPage.getSize();
        console.log(height);
        
  
        // Add the image to the second page at the desired position
        const pngImage = await pdfDoc.embedPng(imgData);
        
        
        const pngDims = pngImage.scale(1); // Scale the image as needed
        const { width: imgWidth, height: imgHeight } = pngImage.scale(1);

        console.log("pngImage",height - pngDims.height - 100,height, pngDims.height);

        console.log(pngDims);
        console.log(pngDims.width);
        console.log(pngDims.height);
        const IsyPosition = pageHeight - imgHeight - 50; // Adjust 50 for padding from the top
        const xPosition = (pageWidth - imgWidth) / 2; // Center horizontally
        const yPosition = 100; // Adjust padding from the bottom (set to 0 for exact bottom)
        // Check if image fits
        if (IsyPosition < 0) {
          console.error('Image is too tall for the available space.');
          return;
        }
        secondPage.drawImage(pngImage, {
          // x: 50, // Adjust the x-coordinate
          // y: height - pngDims.height - 100,
          x: xPosition,
          y: yPosition,
          width: pngDims.width,
          height: pngDims.height,
        });
  
        // Serialize the PDFDocument to bytes (saveable as a file)
        const pdfBytes = await pdfDoc.save();
  
        // Download the modified PDF
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'GrowthCompassReport.pdf';
        link.click();
      }
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      // buttonRef.current.style.display = 'block';
      // backRef.current.style.display = 'block';
    }
  };
  return (
    <>
      <div id="page-content">
        <Container
          className="d-flex flex-column align-items-center vh-100 bor-none"
          style={{ position: "relative" }}
        >
          <div className="">
            <div className="bor-container" style={{border:"none"}}>
              <div
                style={{ maxWidth: "400px", width: "100%" }}
                className="text-center mb-2 position-relative"
              >
                {/* <div className="back-arrow" onClick={handleSubmit} ref={backRef}>
                  <IoArrowBack />
                </div> */}
                <img
                  src={img2}
                  alt="Completed Badge"
                  className="img-fluid mb-4"
                />
                <p style={{fontSize:'24px',fontWeight:500}}>Thank you!</p>
                <p style={{color:'#121416',padding:"0px 38px",fontWeight:400}}>Your detailed report has been sent to your email.</p>
                <div style={{padding:'0px 20px 20px 20px'}}> <hr /></div>
                {/* */}
                <p className="text-uppercase montserrat-subrayada-regular" style={{color:"#5B6F8E",fontSize:'13px',paddingLeft:"10px",textAlign:'start',fontWeight:700}}>Report Summary</p>
              </div>

              <div
                style={{
                  maxWidth: "500px",
                  width: "100%",
                  paddingBottom: "20px",
                }}
                className="px-1"
                ref={photoRef}
              >
                {mainData?.labels ? (
                  <Radar data={mainData} options={options} />
                ) : null}
               
              </div>
              {true && 
                <Button variant="" style={{background:"rgb(178, 9, 93)"}} onClick={takeScreenshotAndSavePDF}>
                {/* <button onClick={takeScreenshotAndSavePDF} style={btnStyle} ref={buttonRef}> */}
                  <FileDownloadIcon style={{ color: "white" }} />
                  </Button>
                }
            </div>
          </div>
        </Container>
      </div>
      
    </>
  );
}

export default ReportSummary;
