import { Dialog } from 'primereact/dialog';
import React from 'react'

const CutModal = ({setVisible,visible}) => {
  return  <Dialog header="Error" visible={visible} onHide={() => {if (!visible) return; setVisible(false); }} className="cut-pri-dia">
  {
    <p className="err-p"><span style={{color:"red",paddingRight:'5px'}}>*</span>You can choose Select 2 options from the list, one for most likely and one for least likely.</p>
  }
  
</Dialog>
}

export default CutModal;


export const BackEndModal = ({setVisible,visible}) => {
  return  <Dialog header="Error" visible={visible} onHide={() => {if (!visible) return; setVisible(false); }} className="cut-pri-dia">
  {
    <p className="err-p"><span style={{color:"red",paddingRight:'5px'}}>*</span>You can choose Select 2 options from the list, one for most likely and one for least likely.</p>
  }
  
</Dialog>
}
