import {configureStore, combineReducers} from '@reduxjs/toolkit';

import {
  persistStore,
  persistReducer,
} from 'redux-persist';

import { SelfSlice } from './selfSlice';
import localStorage from 'redux-persist/lib/storage';
import { TabSlice } from './tabSlice';


const rootReducer = combineReducers({
    SelfSlice: SelfSlice.reducer,
    TabSlice: TabSlice.reducer,
});

const persistConfig = {
  key: 'root',
  storage: localStorage,
  whitelist: [
    'SelfSlice',
    'TabSlice',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});
export const persistor = persistStore(store);
