


export const lblYup = {
  isRequired : "is a required field",
  max150 : "Maximum 150 characters allowed",
  max10  : "Maximum 10 characters allowed",
  max255 : "Maximum 255 characters allowed",
  max100 : "Maximum 100 characters allowed",
  max50 : "Maximum 50 characters allowed",
  max02 : "Maximum 2 characters allowed",
  inValidEmail : "Invalid Email",
  inValidAge : "Invalid Age",
}