import React, { useEffect, useState } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import FlagIcon from "@mui/icons-material/Flag";
import { Box } from "@mui/material";
import { FaRegFlag } from "react-icons/fa6";
import { useSelector } from "react-redux";
const left = [
    3, 9, 13.5, 18, 23, 28, 33, 38, 43, 48, 53, 58, 63, 68, 73, 78, 83, 88, 93, 97
]
// 5 8 12 18 23 28 33 37 43 48 53 58 63 68 73 78 83 88 93 97
function CustomProgressBar() {
    const { steps } = useSelector((data) => data.TabSlice);
    const [progress, setProgress] = useState();
//   const [left,setLeft] = useState(97)

  const handleChange = (event) => {
    const newValue = Number(event.target.value);
    setProgress(newValue);
  };
  useEffect(()=>{
    setProgress(steps*5)
  },[steps])
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",    
        margin: "20px auto",
        // padding: "20px",
        borderRadius: "8px",
        // backgroundColor: progress >= 100 ? "#e0f7fa" : "#f0f4c3", // Change background color based on progress
      }}
    >
      <LinearProgress
        variant="determinate"
        value={progress}
        // value={progress<=70?progress:progress<=90?progress+5:progress}
        sx={{
          height: "24px",
          backgroundColor: "white",
          borderRadius: "50px",
          "& .MuiLinearProgress-bar": {
            backgroundColor: "#a50063",
          },
        }}
      />

      {/* Flag Icon */}
      <Box
        sx={{
          position: "absolute",
        //   left: `${progress}%`,
        //   left: `${(progress>60&&progress<70)?progress-3:progress<=70?progress+4:progress<=90?progress-4:progress+6}%`,
        left: `${left[steps-1]}%`,
          top: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "#a50063",
          borderRadius: "50%",
          width: "30px",
          height: "30px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "2px solid white",
        }}
      >
        {/* <FlagIcon sx={{ color: "white", fontSize: "16px" }} /> */}
        <FaRegFlag style={{ color: "white", fontSize: "14px" }}/>
      </Box>

      {/* Input to change progress */}
      {/* <input
        type="range"
        min="0"
        max="100"
        value={progress}
        onChange={handleChange}
        style={{ marginTop: "20px", width: "100%" }}
      /> */}
    </Box>
  );
}

export default CustomProgressBar;
