


export const getHeader = value => {
   const  result = {"Authorization":`Bearer ${value}`,"Content-Type":"application/json"};
   return result;
}
export const getHeaderWithoutTokn = _ => {
  const result = {"Content-Type":"application/json"};
  return result;
} 
