import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Card,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setActiveNumber } from "../../feature/slice/tabSlice";
import CutModal, { BackEndModal } from "../../utils/model/model1";
import { getStituation } from "./api";
import axios from "axios";
import { BlinkBlur } from "react-loading-indicators";
import em1 from '../../assets/images/em1.png'
import em2 from '../../assets/images/em2.png'
import { ReactComponent as Svg1 } from '../../assets/images/svg1.svg';
import { ReactComponent as Svg2 } from '../../assets/images/svg2.svg';
import CustomProgressBar from "./progressBar";


const styleSelectSuccess = {
  background: "#13908C",
  color: "white",
  border:'solid 1px #13908C'
};
const normalStyle = {
   border:'solid 1px #12141633',
   color:'inherit'
}

const styleSelected = {
  background: "gray",
  color: "white",
  border:'solid 1px #12141633',
};

const styleSelectDanger = {
  background: "#13908C",
  color: "white",
  border:'solid 1px #13908C'
};

const intial = {
    least_likely: null,
    most_likely: null,
    value:"",
}

function SituationAssessment(props) {
  const { componentData } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [visibleBack, setVisibleBack] = useState(false);
  const { steps, activeNumber,previousData,previous } = useSelector((data) => data.TabSlice);
  const { userId } = useSelector((data) => data.SelfSlice);
  const [isLoader,setLoader] = useState(false)
  const [masterData,setMasterData] = useState(intial)

  useEffect(()=>{
     if(steps == 20){
      navigate("/completed");
     }
  },[]);
  const scrollableDivRef = useRef(null);

  // Function to scroll to the top of the div
  const scrollToTop = () => {
    scrollableDivRef.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(()=>{
    scrollToTop()
  },[steps])
 

  const handleSubmit = () => { 
    if (masterData.most_likely && masterData.least_likely) {
      if (steps == 20) {
        navigate("/completed");
      } else {
        handleCompleted();
      }
    } else {
      // setVisible(true);
    }
  };

  const handleCompleted = async () => {
    setLoader(true)
    const header = {
      "Content-Type": "application/json",
    };
    const payload = {
      least_likely: masterData.least_likely,
      situation_id: steps,
      most_likely: masterData.most_likely,
      user_id: userId,
    };
    try {
      const data = await axios.post(window.ASSESSMENT_FEEDBACK, payload, {
        headers: header,
      });
      if (data) {
        getStituation(steps + 1).then(({data})=>{
          dispatch(setActiveNumber({data:{stepsData:data}}))
          dispatch(
            setActiveNumber({
              data: { steps: steps + 1, activeNumber: activeNumber + 1,previousData:[...previousData,payload] },
            })
          );
          setLoader(false)
          clarValues();
        }); 
        
      }
    } catch (error) {
      setLoader(false)
      setVisibleBack(true)
    }finally{
    
    }
  };

  const clarValues = () => {
    setMasterData(intial);
  };



  const handleChange = (id,value) => {
     if(value === "most_likely"){
        if(masterData['least_likely'] == id){
           setMasterData(prev =>({...prev,[value]:id,least_likely:null,value}))
        }else{
            setMasterData(prev =>({...prev,[value]:id,value}))
        }
     }
     if(value === "least_likely"){
        if(masterData['most_likely'] == id){
           setMasterData(prev =>({...prev,[value]:id,most_likely:null,value}))
        }else{
            setMasterData(prev =>({...prev,[value]:id,value}))
        }
     }
  }


  return (
    <>
    
    <Container className="justify-content-center align-items-center">
    <div className="custon-progress-bar"  style={{ maxWidth: "500px", width: "100%",border:"none",padding:"0px 0px",margin:'auto'
        }}>
      <CustomProgressBar />
      </div>
    </Container>
    
    <Container className="d-flex justify-content-center align-items-center pb-1">
     
      <div className="" style={{background:'#F6F6F6',height:'90vh',overflow:'scroll'}}  ref={scrollableDivRef}>
      <Card
        style={{ maxWidth: "500px", width: "100%",background: "white",border:"none",padding:"20px 10px"
        }}
        className="position-relative"
      >
        <div className="mb-3">
          <p className="blur-blue">
           {!previous&&componentData?.situation.val}   
          </p>
          <p className="blur-blue">
           {previous&&componentData?.situation.val}
          </p>
          <p className="" style={{color: "#121416",fontSize:'14px',lineHeight:"17.07px",opacity:.6,fontWeight:500,paddingTop:'10px',marginBottom:0}}>

          
            Select 2 options from the list, one for most likely and one for
            least likely.
          </p>
        </div>
        {!previous&&componentData?.options?.map((item) => {
          return (
            <>
             {/* p-3 */}
              <div className="mb-4 situation-option">
                <div className="b-option">
                <p className="p-3 cus-font-size-16 poppins-thin">{item[1]}</p>
                </div>
                <div className="">
                <div className="cut-row-form cut-row-form-emj">
                  <Col>
                    <Button
                      variant="outline-success"
                      className="hov-none"
                      onClick={()=>handleChange(item[0],"most_likely")}
                      style={(masterData["most_likely"] == item[0]) ? styleSelectSuccess : normalStyle}
                    >
                      <span role="img" aria-label="Most Likely">
                        {/* 😊 */}
                        {/* <img src={em2} width={25} style={{marginRight:"2px"}}/> */}
                        <Svg1 />
                      </span>{" "}
                      <span className="font-weight-bold" style={{fontWeight:500}}>Most Likely</span>
                     
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      variant="outline-danger"
                      className="hov-none"
                      onClick={()=>handleChange(item[0],"least_likely")}
                      style={(masterData["least_likely"]== item[0]) ? styleSelectDanger : normalStyle}
                    >
                      <span role="img" aria-label="Least Likely">
                        {/* 😕 */}
                        <Svg2 />
                         {/* <img src={em1} width={25} style={{marginRight:"2px"}}/> */}
                      </span>{" "}
                      <span className="font-weight-bold" style={{fontWeight:500}}>Least Likely</span>
                    </Button>
                  </Col>
                </div>
                </div>
                
              </div>
            </>
          );
        })}
         {previous&&componentData?.options?.map((item, index) => {
          const find = previousData.find(item => item.situation_id == steps)    
          return (
            <>
              <div className="mb-3 p-3 situation-option">
                <p className="">{item[1]}</p>
                <Row>
                  <Col>
                    <Button
                      variant="outline-success"
                      className="w-100 hov-none"
            
                      style={item[0] == find.most_likely  ? styleSelected : normalStyle}
                    >
                      <span role="img" aria-label="Most Likely">
                        😊
                      </span>{" "}
                      Most Likely  
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      variant="outline-danger"
                      className="w-100 hov-none"
                      style={item[0] == find.least_likely ? styleSelected : normalStyle}
                    >
                      <span role="img" aria-label="Least Likely">
                        😕
                      </span>{" "}
                      Least Likely
                    </Button>
                  </Col>
                </Row>
              </div>
            </>
          );
        })}
        <Button
          variant="primary"
          type="submit"
          className={`custom-btn  ${activeNumber!=steps?"Disabled":(masterData.least_likely&&masterData.most_likely)?"orginal-btn":"opa-btn"}`}
          onClick={handleSubmit}
        >
          {steps === 20 ? "Completed" : "Continue"}
        </Button>
      </Card>
      </div>
      {/* <Button
          variant="primary"
          type="submit"
          className={`custom-btn continue-btn ${activeNumber!=steps?"Disabled":(masterData.least_likely&&masterData.most_likely)?"orginal-btn":"opa-btn"}`}
          onClick={handleSubmit}
        >
          {steps === 20 ? "Completed" : "Continue"}
        </Button> */}
      <CutModal visible={visible} setVisible={setVisible} />
      <BackEndModal visible={visibleBack} setVisible={setVisible} />
    </Container>
    {isLoader&&<div className="custom-loader">
      <BlinkBlur color="rgb(178, 9, 93)" size="medium" text="" textColor="" />
      </div>}
    </>
  );
}

export default SituationAssessment;
