import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const intial = {
    isAuth:false,
    accessToken:null,
    userId:0,
}

const SelfSlice = createSlice({
    name:'token',
    initialState:intial,
    reducers:{
        setUserData:(state,action) => {
            const data = action.payload.data;
            return {
                ...state,
                  ...data
            }
        },
        setResetUser:() => {
            return intial
        }
    }
})

export const {setUserData,setResetUser} = SelfSlice.actions;

export {SelfSlice};