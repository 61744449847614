import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AccessRoute from "./accessRoute";
import DeniedRoute from "./deniedRoute";

const AuthCard = () => {
  const [isIntial, setIntial] = useState(true);
  const { isAuth } = useSelector((data) => data.SelfSlice);
  useEffect(() => {
    setTimeout(() => {
      setIntial(true);
    }, 2000);
  }, []);

  const render = () => {
    return isAuth ? <AccessRoute /> : <DeniedRoute />;
  };

  return <> {isIntial ? render() : <div>Good</div>}</>
};

export default AuthCard;
