import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const intial = {
    activeNumber:1,
    steps:1,
    stepsData:{},
    previousData:[],
    previous:false,
}

const TabSlice = createSlice({
    name:'tab',
    initialState:intial,
    reducers:{
        setActiveNumber:(state,action) => {
            const data = action.payload.data;
            return {
            ...state,
            ...data
            }
        },

        setResetData:() => {
            return intial
        }
    }
})

export const {setActiveNumber,setResetData} = TabSlice.actions;

export {TabSlice};