import logo from './logo.svg';
import './App.css';
import { Provider } from 'react-redux';
import { persistor, store } from './feature/slice';
import { PersistGate } from 'redux-persist/integration/react';
import AuthCard from './components/shield/authCard';
import { BrowserRouter } from 'react-router-dom';
import CustomStepper from './pages/situationAssessment/stepper';

function App() {
  
  return (
    <Provider store={store}>
      <PersistGate loading={<div>Loading</div>} persistor={persistor}>
      <BrowserRouter>
        <AuthCard />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
