import React from 'react'
import { Route, Routes } from 'react-router-dom'
import SelfAssessmentForm from '../../pages/selfAssessemnt/form'

const DeniedRoute = () => {
  return (
    <>
    <Routes>
    <Route path='/' element={<SelfAssessmentForm />} />
    <Route path='/selfassessment' element={<SelfAssessmentForm />} />
    <Route path='*' element={<SelfAssessmentForm />} />
    </Routes>
    </>
  )
}

export default DeniedRoute