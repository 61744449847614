import React, { useState } from "react";
import { Container, Card, Form, Button, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch } from "react-redux";
import { setUserData } from "../../feature/slice/selfSlice";
// import img1 from "../../assets/images/image2.jpg"
import img1 from "../../assets/images/new1.jpeg";

import { RegisterUser } from "../../utils/validation/yup";
import { getHeaderWithoutTokn } from "../../utils/header/getHeader";
import axios from "axios";
import { Button as PrimeButton } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ValidationError } from "yup";
import { BlinkBlur } from "react-loading-indicators";
import { TbGenderFemale, TbGenderMale } from "react-icons/tb";
import { RiGenderlessLine } from "react-icons/ri";
import CustomProgressBar from "../situationAssessment/progressBar";


const educationData = [
  {
    id:1,education:"High school",
  },
  {
    id:2,education:"Graduate",
  },
  {
    id:3,education:"Post Graduate",
  },
  {
    id:4,education:"Doctorate",
  },
  {
    id:5,education:"Other Professional Degree",
  }
]
function SelfAssessmentForm() {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const [state, setState] = useState({
    name: "",
    age: "",
    gender: "",
    email: "",
    city: "",
    education: "",
    occupyType: "",
  });
  const [error, setError] = useState(null);
  const { name, age, email, gender, city, education, occupyType } = state;
  const isStart =
    name && age && email && gender && city && education && occupyType;

  const handleSubmit = (event) => {
    event.preventDefault();

    RegisterUser.validate(state, { abortEarly: false })
      .then(() => {
        submitData();
      })
      .catch((error) => {
        const err = {};
        error.inner.forEach((ValidationError) => {
          err[ValidationError.path] = ValidationError.message;
        });
        setVisible(true);
        setError(err);
      });
  };
  const submitData = async () => {
    setLoader(true);
    try {
      const h = getHeaderWithoutTokn();

      const payload = {
        full_name: name,
        age: parseInt(age),
        gender: gender,
        email_address: email,
        city: city,
        education: education,
        occupation_type: occupyType,
      };
      const { data } = await axios.post(window.REGISTER_USER, payload, {
        headers: h,
      });

      if (data?.detail) {
        setError({ detail: data?.detail });
        setVisible(true);
        setLoader(false);
        return;
      }
      dispatch(setUserData({ data: { isAuth: true, userId: data?.id } }));
      setLoader(false);
    } catch (error) {
      setLoader(false);
      // throw new Error("Find Out");
      if (error.status == 409) {
        setError({ detail: "Email address is already registered" });
        setVisible(true);
      }
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name == "age") {
      const val = value.replace(/\D/g, "");
      setState((prev) => ({ ...prev, [name]: val }));
    } else {
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleGender = (value) => {
    setState((prev) => ({ ...prev, gender: value }));
  };
  const handleOccupy = (value) => {
    setState((prev) => ({ ...prev, occupyType: value }));
  };
  return (
    <>
      <Container className="d-flex justify-content-center align-items-center custom-container p-0">
        <Card
          style={{ width: "100%", border: "none" }}
          className="pb-4 cust-bos-card"
        >
          <div className="mb-1 banner-fixed">
            <img src={img1} alt="Illustration" className="img-fluid mb-3" />
            <p className="cust-pl-2 cust-pr-2 montserrat-subrayada-regular">
              Growth Collective’s Self Assessment
            </p>
            <p className="cust-pl-2 cust-pr-2 montserrat-subrayada-regular-com">
              <span className="yellow">Please fill in the details below</span>
            </p>
          </div>

          <Form onSubmit={handleSubmit} className="cust-pl-2 cust-pr-2">
            <Form.Group controlId="fullName" className="cust-form-group">
              {/* <Form.Label>Full Name</Form.Label> */}
              <Form.Control
                type="text"
                placeholder="Full Name"
                name="name"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="age" className="cust-form-group">
              {/* <Form.Label>Age</Form.Label> */}
              <Form.Control
                type="text"
                placeholder="Age"
                name="age"
                value={state.age}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group
              controlId="gender"
              className="cust-form-group cus-gender"
            >
              {/* <Form.Label>Gender</Form.Label> */}
              <div className="cut-row-form">
                <Col xs="4">
                  <Button
                    variant="outline-secondary"
                    className={`w-100 hov-gr h-48 ${
                      "Male" === gender ? "selected-clr" : null
                    }`}
                    onClick={() => handleGender("Male")}
                  >
                    <span className="cut-mr-1">
                      <TbGenderMale size={21} />
                    </span>
                    <span style={{ fontSize: "16px" }}>Male</span>
                  </Button>
                </Col>
                <Col xs="4">
                  <Button
                    variant="outline-secondary"
                    className={`w-100 hov-gr h-48 ${
                      "Female" === gender ? "selected-clr" : null
                    }`}
                    onClick={() => handleGender("Female")}
                  >
                    <span className="cut-mr-1">
                      <TbGenderFemale size={21} />
                    </span>
                    <span style={{ fontSize: "16px" }}>Female</span>
                  </Button>
                </Col>
                <Col xs="4">
                  <Button
                    variant="outline-secondary"
                    className={`w-100 hov-gr h-48 ${
                      "Other" === gender ? "selected-clr" : null
                    }`}
                    onClick={() => handleGender("Other")}
                  >
                    <span className="cut-mr-1">
                      <RiGenderlessLine size={18} />
                    </span>
                    <span style={{ fontSize: "16px" }}>Other</span>
                  </Button>
                </Col>
              </div>
            </Form.Group>

            <Form.Group controlId="email" className="cust-form-group">
              {/* <Form.Label>Email Address</Form.Label> */}
              <Form.Control
                type="text"
                placeholder="Email Address"
                name="email"
                onChange={handleChange}
              />
              <Form.Text className="text-muted">
                Your report will be sent to this email address
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="city" className="cust-form-group">
              {/* <Form.Label>City</Form.Label> */}
              <Form.Control
                type="text"
                placeholder="City"
                name="city"
                onChange={handleChange}
              />
            </Form.Group>

            {/* <Form.Group controlId="education" className="cust-form-group">
              <Form.Control
                type="text"
                placeholder="Education"
                name="education"
                onChange={handleChange}
              />
            </Form.Group> */}
            <Form.Group controlId="education" className={`cust-form-group ${education?"":"form-select-selected"}`}>
              <Form.Select size="lg" onChange={(event)=>setState((prev) => ({ ...prev, education: event.target.value }))}>
                <option value=""><span>Education</span></option>
                {educationData?.map(item =>
                  <option key={item.id} value={item.education}>{item.education}</option>
                )}
              </Form.Select>
            </Form.Group>

            <Row className="mb-4">
              <Col xs="6">
                <Button
                  variant="outline-secondary"
                  className={`w-100 hov-gr ${
                    "Professional" === occupyType ? "selected-clr" : null
                  }`}
                  onClick={() => handleOccupy("Professional")}
                >
                  Professional
                </Button>
              </Col>
              <Col xs="6">
                <Button
                  variant="outline-secondary"
                  className={`w-100 hov-gr ${
                    "Self-Employed" === occupyType ? "selected-clr" : null
                  }`}
                  onClick={() => handleOccupy("Self-Employed")}
                >
                  Self-Employed
                </Button>
              </Col>
              {/* <Col xs="12" style={{ margin: "20px 0" }}>
                <Button
                  variant="outline-secondary"
                  className={`w-100 hov-gr ${
                    "Designer" === occupyType ? "selected-clr" : null
                  }`}
                  onClick={() => handleOccupy("Designer")}
                >
                  Designer
                </Button>
              </Col>
              <Col xs="12" style={{ marginBottom: "20px" }}>
                <Button
                  variant="outline-secondary"
                  className={`w-100 hov-gr ${
                    "Growth Collective" === occupyType ? "selected-clr" : null
                  }`}
                  onClick={() => handleOccupy("Growth Collective")}
                >
                  Growth Collective
                </Button>
              </Col> */}
            </Row>

            <Button
              variant="primary"
              type="submit"
              className={`${!isStart ? "opa-btn" : ""} w-100 custom-btn`}
            >
              Start Assessment
            </Button>
          </Form>
        </Card>
      </Container>
      {visible && (
        <div
          className="card flex justify-content-center"
          style={{ border: "none" }}
        >
          {/* <PrimeButton label="Show" icon="pi pi-external-link" onClick={() => setVisible(true)} /> */}
          <Dialog
            header="Error"
            visible={visible}
            onHide={() => {
              if (!visible) return;
              setVisible(false);
            }}
            className="cut-pri-dia"
          >
            {error
              ? Object.keys(error)?.map((it) => (
                  <p className="err-p">
                    <span style={{ color: "red", paddingRight: "5px" }}>*</span>
                    {error[it]}
                  </p>
                ))
              : null}
          </Dialog>
        </div>
      )}

      {isLoader && (
        <div className="custom-loader">
          <BlinkBlur
            color="rgb(178, 9, 93)"
            size="medium"
            text=""
            textColor=""
          />
        </div>
      )}
    </>
  );
}

export default SelfAssessmentForm;
